import React, { HTMLProps, useContext, useMemo } from "react";
import {
   Typography as Typo,
   ListItem as Item,
   Menu,
   MenuHandler,
   MenuList as List,
   MenuItem as MItem,
} from "@material-tailwind/react";
import { useLocation } from '@reach/router';

import { AppContext } from "../../context/appContext";
import { routes, common } from "../../utils";
import { Link } from "gatsby";
import { ChevronDown } from '@styled-icons/evil';
import { cn } from "../../utils/common";

import {
   NavigationMenu,
   NavigationMenuContent,
   NavigationMenuIndicator,
   NavigationMenuItem,
   NavigationMenuLink,
   NavigationMenuList,
   NavigationMenuTrigger,
   NavigationMenuViewport,
} from './NavigationMenu/index'

const { clsMerge } = common;
const MenuList = List as any;
const MenuItem = MItem as any

const merger = clsMerge(
   'navbar__link text-black font-medium hover:text-primary-200 cursor-pointer',
);

export const MegaMenu = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(({ style, ...props }, ref) => {

   const { links: { headers: links } } = useContext(AppContext);
   const { pathname } = useLocation();

   return (
      <div style={{ textWrap: 'nowrap', ...style }} {...props} ref={ref}>
         {links.map((item, key) => {

            const { value, label, target = 'self' } = item;
            const active = routes.isRouteMatch(pathname, value);
            const className = merger({ className: active ? 'text-primary-200' : '' });

            return (
               < span
                  className='navbar__item'
                  key={key.toString() ?? ''}
               >
                  {item?.children && item.children.length ? (
                     <Dropdown
                        className={className}
                        label={label}
                        item={item}
                        rootClassName={item?.rootClassName}
                     />
                  ) : (
                     <Link
                        className={className}
                        to={item.to ?? '/'}
                        target={target}
                     >
                        {item.label}
                     </Link>
                  )}
               </span >
            )
         })}
      </div>
   )
})

type DropdownTypes = {
   label: string
   item: { [key: string]: any }
   rootClassName?: any
}

const Dropdown = React.forwardRef<HTMLSpanElement, HTMLProps<HTMLSpanElement> & DropdownTypes>(
   ({ label, item, className = '', rootClassName, ...props }, ref) => {
      const [isMenuOpen, setIsMenuOpen] = React.useState(false);

      const { children } = item;

      const renderItems = useMemo(() => {
         if ((!children || !children.length)) return null;
         return children.map(
            ({ label, to, desc, icon, image, target = 'self', extra }, key: number) => (
               <Link to={to} key={key} target={target}>
                  <ListItemComp label={label} desc={desc} icon={icon} image={image} extra={extra} />
               </Link>
            ),
         )
      }, [children]);

      const labelCls = clsMerge(
         `${className} ${isMenuOpen ? 'text-primary-200' : ''}`,
      )

      return (
         <Menu
            open={isMenuOpen}
            handler={setIsMenuOpen}
            placement='bottom-start'
            offset={5}
            allowHover={true}
         >
            <MenuHandler>
               <span className={labelCls()} {...props} ref={ref}>
                  {label}
                  <ChevronDown className={`${isMenuOpen ? 'rotate-180 ' : ''} h-6 w-6 stroke-[1]`} />
               </span>
            </MenuHandler>
            <MenuList className="hidden min-w-[fit] max-w-[fit] rounded-xl lg:block app-mega-dropdown-boxShadow">
               <div className={cn("grid grid-cols-2 flex-wrap p-2 outline-none border-0", rootClassName)}>
                  {renderItems}
               </div>
            </MenuList>
         </Menu>
      )
   })


type ListItemCompProps = {
   icon?: React.ReactElement
   label?: string
   desc?: string
   image?: HTMLProps<HTMLImageElement>
   extra?: any
   className?: string
}

export const ListItemComp = ({ icon, label, desc, image, extra = null, className }: ListItemCompProps) => {

   return (
      <MenuItem
         className={
            cn("flex items-center group gap-3 rounded-lg  overflow-x-hidden border-none hover:bg-primary-200/5",
            className)
         }
      >
         <div>
            {""}
            {icon ? React.createElement(icon as any, {
               className: "h-5 w-5 group-hover:text-primary-200",
            }) : (
               image?.src ? (
                  <img className={cn("h-5 w-5", image?.className)} {...image} />
               ) : null
            )}
         </div>
         <div className="flex flex-row items-center gap-2 justify-start w-full">
            <div
               className="flex flex-col items-start justify-start gap-0"
            >
               <span
                  className="flex text-gray-900 items-center group-hover:text-primary-200 text-sm font-bold mb-[1px]

                  "
               >
                  {label}
               </span>
               <span
                  className="text-sm text-gray-600 font-[400] leading-6 whitespace-nowrap overflow-hidden text-ellipsis !m-0"
               >
                  {desc}
               </span>
            </div>
            {extra ? (
               <span className="rounded-md text-[9px] font-semibold p-[5px] bg-primary-100/5 text-primary-200 text-nowrap">
                  {extra}
               </span>
            ) : null}
         </div>
      </MenuItem>
   )
}