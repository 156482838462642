import React, { createContext } from "react"
import { LinksTypes, SettingsNodeTypes } from "../types/node";

export const AppContext = createContext<AppContextTypes>({} as AppContextTypes);

type AppContextTypes = {
   settings?: SettingsNodeTypes,
   links: LinksTypes
}

type SettingsProviderTypes = {
   children?: React.ReactNode
   value?: AppContextTypes
}
export const AppProvider = ({ children, value }: SettingsProviderTypes) => {
   return (
      <AppContext.Provider value={value as AppContextTypes}>
         {children}
      </AppContext.Provider>
   )
}