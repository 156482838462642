import { Link } from 'gatsby';
import React, { HTMLProps, LegacyRef } from 'react';


export const Brand = React.forwardRef<HTMLImageElement,
   HTMLProps<HTMLImageElement>
>((props, ref: LegacyRef<any>) => {
   const { className, ...rest } = props;
   return (
      <Link to='/' ref={ref}>
         <img
            src='/images/unz_dark_logo.svg'
            alt="Unizo Documentation | Unizo Docs"
            className={className}
            {...rest}
            height={28}
            width={119}
         />
      </Link>
   )
})
