import { Link } from 'gatsby';
import React from 'react';

const SEPARATOR = '•';

const iconMapper = {
   x: (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width={20}
         height={20}
         viewBox="0 0 20 20"
         fill="none"
      >
         <g id="Social Media">
            <path
               id="Vector"
               d="M11.3214 8.93666L16.4919 3.05566H15.2667L10.7772 8.16205L7.1914 3.05566H3.05566L8.47803 10.7774L3.05566 16.9446H4.28097L9.022 11.552L12.8088 16.9446H16.9446L11.3211 8.93666H11.3214ZM9.64322 10.8455L9.09382 10.0765L4.72246 3.95821H6.60445L10.1322 8.8959L10.6816 9.66481L15.2672 16.083H13.3852L9.64322 10.8458V10.8455Z"
               fill="white"
            />
         </g>
      </svg>
   ),
   linkedIn: (
      <svg viewBox="0 0 24 24" aria-hidden="true" focusable="false" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="StyledIconBase-sc-ea9ulj-0 kczbJK h-[1.3rem] w-[1.3rem] text-white "><circle cx="4.983" cy="5.009" r="2.188"></circle><path d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"></path></svg>
   ),
   github: (
      <svg
         height={32}
         aria-hidden="true"
         viewBox="0 0 24 24"
         version="1.1"
         width={32}
         data-view-component="true"
         className="octicon octicon-mark-github"
      >
         <path d="M12.5.75C6.146.75 1 5.896 1 12.25c0 5.089 3.292 9.387 7.863 10.91.575.101.79-.244.79-.546 0-.273-.014-1.178-.014-2.142-2.889.532-3.636-.704-3.866-1.35-.13-.331-.69-1.352-1.18-1.625-.402-.216-.977-.748-.014-.762.906-.014 1.553.834 1.769 1.179 1.035 1.74 2.688 1.25 3.349.948.1-.747.402-1.25.733-1.538-2.559-.287-5.232-1.279-5.232-5.678 0-1.25.445-2.285 1.178-3.09-.115-.288-.517-1.467.115-3.048 0 0 .963-.302 3.163 1.179.92-.259 1.897-.388 2.875-.388.977 0 1.955.13 2.875.388 2.2-1.495 3.162-1.179 3.162-1.179.633 1.581.23 2.76.115 3.048.733.805 1.179 1.825 1.179 3.09 0 4.413-2.688 5.39-5.247 5.678.417.36.776 1.05.776 2.128 0 1.538-.014 2.774-.014 3.162 0 .302.216.662.79.547C20.709 21.637 24 17.324 24 12.25 24 5.896 18.854.75 12.5.75Z" />
      </svg>

   ),
   youTube: (
      <svg
         className="w-[1.25rem] h-[0.875rem] text-white"
         viewBox="0 0 16 12"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.9191 1.10651C14.558 1.27906 15.0602 1.78251 15.2299 2.42069C15.5388 3.57887 15.5388 5.99687 15.5388 5.99687C15.5388 5.99687 15.5388 8.41487 15.2299 9.57306C15.0578 10.2136 14.5556 10.7171 13.9191 10.8872C12.7638 11.1969 8.12875 11.1969 8.12875 11.1969C8.12875 11.1969 3.49603 11.1969 2.33844 10.8872C1.69952 10.7147 1.19735 10.2112 1.0276 9.57306C0.71875 8.41487 0.71875 5.99687 0.71875 5.99687C0.71875 5.99687 0.71875 3.57887 1.0276 2.42069C1.1997 1.78015 1.70188 1.27669 2.33844 1.10651C3.49603 0.796875 8.12875 0.796875 8.12875 0.796875C8.12875 0.796875 12.7638 0.796875 13.9191 1.10651ZM10.4981 5.99687L6.6481 8.22578V3.76796L10.4981 5.99687Z"
            fill="white"
         />
      </svg>
   )
}

const iconWrapperCls = {
   x: 'w-8 h-8 rounded-full transition-all duration-500 flex justify-center items-center bg-[#33CCFF] hover:bg-gray-900',
   linkedIn: 'relative w-8 h-8 rounded-full transition-all duration-500 flex justify-center items-center bg-[#337FFF]  hover:bg-gray-900',
   github: 'relative w-8 h-8 rounded-full transition-all duration-500 flex justify-center items-center bg-white  hover:bg-white ',
   youTube: 'relative w-8 h-8 rounded-full transition-all duration-500 flex justify-center items-center bg-[#FF0000]  hover:bg-gray-900 ',
}

const SocialLinks = ({ links }) => {
   return (
      <div className="flex mt-4 space-x-4 sm:justify-center lg:mt-0 ">
         {links.map(({ type, to = '/', target = '_self' }, index) => {
            return (
               <Link
                  key={index}
                  to={to}
                  aria-label={index}
                  target={target}
                  className={iconWrapperCls[type]}
               >
                  {iconMapper?.[type]}
               </Link>
            )
         })}
      </div>
   )
}

export const BottomFooter = ({ links, socialLinks }) => {
   return (
      <div className="py-7 border-t border-gray-200">
         <div className="flex items-center justify-center flex-col lg:justify-between lg:flex-row">
            <img alt="Unizo" className="h-5 w-[100px] mb-5 lg:h-12" src="/images/unz_dark_logo.svg" />
            <div className="text-xs lg:text-sm flex flex-row gap-2.5 text-gray-500 ">
               {`©${new Date().getFullYear()} Unizo LLC. `}
               {links.map(({ name, to }, index) => (
                  <>
                     <Link className='text-gray-500 hover:underline' to={to ?? '/'} >{name}</Link>
                     {index < links.length - 1 && SEPARATOR}
                  </>
               ))}
            </div>
            <SocialLinks links={socialLinks} />
         </div>
      </div>
   )
}