import React, { } from 'react';
import { Link } from "gatsby";

import '../../styles/header.css';
import { Brand } from './Brand';
import { MegaMenu } from './megaMenu';

const appLink = 'https://app.unizo.io';

const MenuIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, ref) => {
   return (
      <svg width="30" height="30" viewBox="0 0 30 30" aria-hidden="true" {...props} ref={ref}>
         <path stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" d="M4 7h22M4 15h22M4 23h22"></path>
      </svg>
   )
})

type HeaderTypes = {
   mode?: 'dark' | 'light',
   onOpen: (isOpen: boolean) => void;
   open?: boolean
}

export const Header = (props: HeaderTypes) => {
   const { onOpen } = props;

   return (
      <nav
         aria-label="Main"
         className="navbar sticky top-0 navbar--fixed-top z-[999]"
      >
         <div className='w-full h-full flex justify-between items-center layout'>
            <div className='flex flex-row items-center gap-10'>
               <div className='flex flex-row items-center gap-2'>
                  <MenuIcon
                     className='cursor-pointer block xl:hidden'
                     onClick={() => {
                        onOpen(true);
                     }}
                  />
                  <Brand className="mt-0" />
               </div>
               <div className='hidden xl:block'>
                  <MegaMenu />
               </div>
            </div>
            <div className='flex flex-row items-center justify-start gap-4'>
               <Link to={appLink} className='hidden lg:block text-black text-sm hover:text-primary-200 font-medium' >Log In</Link>
               <Link
                  to="/lets-chat"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary"
               >
                  Talk to an expert →
               </Link>
            </div>
         </div>
      </nav>
   )
}
